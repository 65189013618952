.main-bg-image {
  background-image: url("../images/mainBg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.fs-xs {
  font-size: 1rem;
}

.fs-xs-sm {
  font-size: 1.15rem;
}
.fs-sm {
  font-size: 1.35rem;
}

.fs-md {
  font-size: 1.5rem;
}

.fs-lg {
  font-size: 2rem;
}
.fs-lg-static {
  font-size: 30px;
}

.fs-xl {
  font-size: 2.5rem;
}

.fw-thin {
  font-weight: 200;
}

.fw-light {
  font-weight: 300;
}

.fw-normal {
  font-weight: 400;
}

.fw-bold {
  font-weight: 600;
}

.ls-md {
  letter-spacing: 9px;
}

.text-light-gray {
  color: #8a8a8a;
}

.text-gray {
  color: #7d7d7d;
}

.text-black {
  color: #000 !important;
}

.product-img {
  height: 300px;
  object-fit: cover;
  max-width: 100%;
  object-fit: contain;
  transition: filter 0.3s ease;
}
.product-img.greyed-out {
  opacity: 80%;
  filter: grayscale(100%) contrast(0%) brightness(85%) sepia(10%);
}

.product-img:hover {
  filter: none;
}

.all-products-wrapper .ant-card-body{
  text-align: center;
}

.scroll-within {
  max-height: calc(100vh - 100px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.home-parent ::-webkit-scrollbar {
  display: none;
}
.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 9px;
  padding-left: 9px;
  width: 95% !important;
}

.ant-card {
  background: transparent !important;
}

.ant-card-body {
  padding: 0 !important;
}

.underline{
  text-decoration: underline;
}

.custom-top-padding{
  padding-top: 100px !important;
}


/* For custom-left-drawer */
.custom-left-drawer {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  height: 100vh;
  width: 330px;
  border-radius: 8px;
  padding: 1.5rem;
  padding-left: 2.9rem;
  background-color: rgb(200 200 200 / 10%);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

.variable-scroll{
  overflow-y: scroll !important;
}

.custom-left-drawer.d-block {
  transform: translateX(0);
  box-shadow: 2px 1px 6px 2px rgba(0, 0, 0, 0.14);
  -webkit-box-shadow: 2px 1px 6px 2px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 2px 1px 6px 2px rgba(0, 0, 0, 0.14);
}

/* For custom-right-drawer */
.custom-right-drawer {
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  height: 100vh;
  width: 330px;
  border-radius: 8px;
  padding: 1.5rem;
  background-color: rgb(200 200 200 / 10%);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

.custom-right-drawer.d-block {
  transform: translateX(0);
  box-shadow: 4px 2px 12px 2px rgba(0, 0, 0, 0.28);
  -webkit-box-shadow: 4px 2px 12px 2px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 4px 2px 12px 2px rgba(0, 0, 0, 0.28);
}

.custom-px{
  padding-left: 6rem;padding-right: 6rem;
}

@media (max-width: 768px) {
  .custom-px{
    padding-left: 0;padding-right:0;
  }
}

@media (max-width: 768px) {
  .custom-left-drawer,.custom-right-drawer{
    width: 100% !important;
    box-shadow: none !important;
  }
}

/* media query for header */
@media (max-width: 576px) {
  .fs-sm {
    font-size: 1rem;
  }
  .fs-lg {
    font-size: 1rem !important;
  }
  .fs-xl {
    font-size: 1.1rem !important;
  }
  .ls-md {
    letter-spacing: 1px !important;
  }
}
@media (max-width: 768px) {
  .fs-lg {
    font-size: 1.45rem;
  }
  .fs-xl {
    font-size: 1.75rem;
  }
}

/* 

@media (max-width: 992px) {
  .fs-lg {
    font-size: 1.5rem;
  }
  .fs-xl {
    font-size: 2rem;
  }  
}

@media (max-width: 1400px) {
  .fs-lg {
    font-size: 2rem;
  }
  .fs-xl {
    font-size: 2.5rem;
  }  
} */

.ant-drawer-header {
  background-color: rgba(255, 255, 255, 0.073);
  backdrop-filter: blur(3.4px);
  border-radius: 8px;
  padding: 0;
}

.ant-drawer-content {
  background-color: rgba(137, 137, 137, 0.1);
  backdrop-filter: blur(4px);
  border-radius: 8px;
  padding: 0;
}

.ant-drawer-left > .ant-drawer-content-wrapper {
  box-shadow: 6px 0 16px -8px rgb(0 0 0 / 1%), 9px 0 28px 0 rgb(0 0 0 / 1%),
    12px 0 48px 16px rgb(0 0 0 / 1%);
}

.glass-bg {
  background-color: rgba(123, 123, 123, 0.1);
  backdrop-filter: blur(4px);
  border-radius: 8px;
  padding: 0;
  box-shadow: 8px 9px 28px -9px rgba(7, 7, 0, 0.2) !important;
-webkit-box-shadow: 8px 9px 28px -9px rgba(7, 7, 0, 0.2) !important;
-moz-box-shadow: 8px 9px 28px -9px rgba(7,7, 0, 0.2) !important;
    border-radius: 0.75rem !important;
}
.menu .ant-drawer-body {
  padding: 24px 24px 24px 48px;
}

.cart .ant-drawer-body {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
} 

.cart .ant-drawer-content-wrapper{
  width: 378px !important;
}

@media (max-width: 576px) {
  .cart .ant-drawer-content-wrapper{
  width: 100% !important;
  }
}

/* .arrow {
  cursor: pointer;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid black;
    transform: rotate(45deg);
  } */

  .arrow-right-parent{
    top: 0;
    right: 10px;
    position: absolute;
    cursor: pointer;
  }
  
.arrow-right {
  background: #000;
  height: 1.5px;
  width: 80px;
  margin: 0 auto;
  

  &:before,
  &:after {
    content: "";
    background: #000;
    position: absolute;
    height: 1.5px;
    width: 12px;
  }

  &:before {
    right: -2px;
    bottom: 4px;
    transform: rotate(-45deg);
  }

  &:after {
    right: -2px;
    top: 4px;
    transform: rotate(45deg);
  }
}

.arrow-left-parent{
  top: 0;
  left: 10px;
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.arrow-left {
  background: #000;
  height: 1.5px;
  width: 80px;
  margin: 0 auto;
 

  &:before,
  &:after {
    content: "";
    background: #000;
    position: absolute;
    height: 1.5px;
    width: 12px;
  }

  &:before {
    left: -2px;
    bottom: 4px;
    transform: rotate(45deg);
  }

  &:after {
    left: -2px;
    top: 4px;
    transform: rotate(-45deg);
  }
}

.slider-wrapper {
  border: none !important;
}

.bottom-bars {
  height: 2px;
  background-color: #afafaf;
  flex: 1;
  /* padding: 10px; */
}

.product-carousel .slick-active .bottom-bars {
  background-color: #000;
}

.product-carousel .slick-dots {
  display: flex;
}

.product-carousel .slick-dots li {
  width: 100%;
  height: 2px;
}

.product-carousel .slick-dots {
  position: unset !important;
  margin-top: 23px !important;
}
.details-table th,
.details-table td {
  text-align: right;
}

.sizes-container {
  border-bottom: 1px solid #000;
}

.sizes-container.disabled{
  pointer-events: none !important;
  /* cursor: no-drop !important; */
  color: #a4a4a4 !important;
  border-bottom: 1px solid #a4a4a4 !important;
}
/* .details-col{
  flex-direction: column;
  justify-content: space-between;
  display: flex;
} */
.text-adjust {
  text-align: right !important;
}

@media (max-width: 576px) {
  .text-adjust {
    text-align: left !important;
  }
}

.text-link {
  color: #577cff;
}

 .glass-bg .ant-btn-primary{
  background-color: #000 !important;
  color: #fff !important;
  border: none;
 }

 .thumbnail{
  width: 60px;
  height: 60px;
  object-fit: cover;
 }

 .add-btn,
 .add-btn:focus,
 .add-btn:active{
  color: #fff !important;
  background-color: #000 !important;
 }

 .add-btn:hover{
  background-color: #fff !important;
  color: #000 !important;
 }

 .ant-tag{
  border: 0.5px solid #d9a02e;
  font-size: 0.8rem;
}
/* 
.ant-upload-picture-card-wrapper{
  max-height: 320px;
  overflow-y: auto;
}

.ant-upload-picture-card-wrapper::-webkit-scrollbar {
  width: 6px;
  border-radius: 50%;

}

.ant-upload-picture-card-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
.ant-upload-picture-card-wrapper::-webkit-scrollbar-thumb {
  background: #d2d2d2; 
}

.ant-upload-picture-card-wrapper::-webkit-scrollbar-thumb:hover {
  background: #adadad; 
} */

.react-tel-input .form-control:hover{
  border: #d9a02e !important;
  border-right-width: 1px !important;
}
.react-tel-input .form-control:focus{
  border: #d9a02e !important;
  box-shadow: 0 0 0 2px rgba(255, 169, 24, 0.2) !important;
}
.react-tel-input .form-control{
  height: 40px !important;
  border-radius: 0.625rem !important;
  width: 100% !important;
  border: 1px solid #e6ebf1 !important;
}

.react-tel-input *{
  font-family: 'IBM Plex Mono', monospace;
}

.truncate {
  /* width: 250px; */
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.product-parent{
  position: relative;
}

.product-child{
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #fff;
  padding: 4px 14px;
  border-radius: 0.25rem;
}

